<template>
  <v-container fluid v-if="!loading">
    <SubHeader
      :title="title"
      :toClone="true"
      @edit="edit"
      @saveChanges="confirmSaveChanges"
      @cencel="cencel"
      @delEl="confirmDelEl"
      @clone="clone"
    />
    <RForm
      :editable="editable"
      :fields="fields"
      :values="values"
    />
    <div>
      <span class="text--disabled">מספר פנימי:</span>
      <span class="caption">{{ id }}</span>
    </div>

    <!-- Save Confirmation Dialog -->
    <v-dialog v-model="saveDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">לשמור</v-card-title>
        <v-card-text>לשמור שינויים?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="saveDialog = false">ביטול</v-btn>
          <v-btn color="green darken-1" text @click="saveChanges">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">למחוק</v-card-title>
        <v-card-text>למחוק איש קשר?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">ביטול</v-btn>
          <v-btn color="green darken-1" text @click="delEl">מחק</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import RForm from "@/components/app/common/Form/RForm";
import SubHeader from "@/components/app/common/Form/SubHeader";

export default {
  name: "Home",
  props: {
    id: {},
    title: {},
    fieldsGetter: {
      required: true,
    },
    fieldsGetFunctionName: {
      required: true,
    },
    getFieldValuesFunc: {
      required: true,
    },
    updateFieldValuesFunc: {
      required: true,
    },
    removeFieldValuesFunc: {
      required: true,
    },
    addFieldValuesFunc: {
      required: true,
    },
  },
  data: () => ({
    values: {},
    editable: true,
    saveDialog: false,
    deleteDialog: false,
  }),
  methods: {
    edit() {
      this.editable = true;
    },
    async cencel() {
      await this.getContactPerson();
      this.editable = false;
    },
    async saveChanges() {
      this.saveDialog = false;
      await this.$store.dispatch("loading", true);
      this.values.id = this.id;
      await this.$store.dispatch(this.updateFieldValuesFunc, this.values);
      this.editable = false;
      await this.$store.dispatch("loading", false);
    },
    async delEl() {
      this.deleteDialog = false;
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch(this.removeFieldValuesFunc, this.id);
      this.editable = false;
      await this.$store.dispatch("loading", false);
    },
    async clone() {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch(this.addFieldValuesFunc, this.values);
      this.editable = false;
      await this.$store.dispatch("loading", false);
    },
    async getContactPerson() {
      let cp = await this.$store.dispatch(this.getFieldValuesFunc, this.id);
      this.values = Object.assign(this.values, cp);
    },
    confirmSaveChanges() {
      this.saveDialog = true;
    },
    confirmDelEl() {
      this.deleteDialog = true;
    },
  },
  components: {
    SubHeader,
    RForm,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.getContactPerson();
    await this.$store.dispatch(this.fieldsGetFunctionName);
    this.editable = false;
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading"]),
    fields() {
      return this.$store.getters[this.fieldsGetter];
    },
  },
};
</script>